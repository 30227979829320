import InfoBoxForm from "@/views/info-box/infoBoxForm";
import Component, { mixins } from "vue-class-component";
@Component({
  inject: {
    languageService: "languageService",
    eventService: "eventService",
    infoCardPageService: "infoCardPageService",
  }
})
export default class InfoCardPageForm extends mixins(InfoBoxForm) {
  model = {};
  internal_events = [];
  cardLayouts = [];


  infoBoxRequiredFields() {
    return !!this.model?.infoBoxData?.enableInfoBox;
  }

  get elements() {
    const infoBoxDataFields = [
      {
        id: "enableInfoBox",
        label: this.translations.labels.infoCardPage_form_enableInfoBox,
        type: "switch"
      },
      ...this.infoBoxElements
    ];

    return [
      {
        legend: this.translations.labels.commonFieldset_generalData,
        type: "fieldset",
        group: "default",
        collapsible: true,
        open: true,
        resetColumns: true,
        elements: [
          {
            id: "idEvent",
            label: this.translations.labels.events,
            type: "select",
            items: this.events,
            required: true
          },
          {
            id: "code",
            label: this.translations.labels.infoCardPage_form_code,
            type: "text",
            required: true,
            rules: "min:4"
          },
          {
            id: "cardWithDescription",
            label: this.translations.labels.infoCardPage_form_cardWithDescription,
            type: "checkbox",
            required: false,
          },
          {
            id: "isCircleImage",
            label: this.translations.labels.infoCardPage_form_isCircleImage,
            type: "checkbox",
            required: false,
            default: false,
          },
          {
            id: "cardLayout",
            label: this.translations.labels.infoCardPage_form_cardLayout,
            type: "select",
            items: this.cardLayoutsOptions,
            required: false
          },
          {
            id: "localizations",
            type: "localizations",
            header: this.translations.labels.form_localizations,
            languages: this.languages,
            resetColumns: true,
            elements: [
              {
                id: "title",
                label: this.translations.labels.form_title,
                type: "text",
                required: true,
              },
              {
                id: "imageUri",
                label: this.translations.labels.infoCardPage_form_imageUri,
                type: "media",
                mediaType: "background",
                required: true
              },
            ],
          }
        ]
      },
      {
        id: "infoBoxData",
        legend: this.translations.labels.infoCardPage_form_infoBoxData,
        type: "fieldset",
        group: "object",
        collapsible: true,
        resetColumns: true,
        elements: infoBoxDataFields
      }
    ];
  }

  get cardLayoutsOptions() {
    return this.cardLayouts.map((c) => {
      let currentLayout = c.replace(/([A-Z])/g, " $1").trim(); // set a transparent character to maintain the height :)

      return {
        value: c,
        label: currentLayout.charAt(0) + currentLayout.substring(1).toLowerCase(),
      };
    });
  }

  get events() {
    return this.internal_events.map((i) => {
      return {
        languages: i.languages,
        label: i.code,
        value: i.id,
      };
    });
  }

  get languages() {
    if (
      this.model.idEvent === undefined ||
      this.model.idEvent === null ||
      this.events === undefined ||
      this.events === null
    )
      return [];
    const event = this.events.filter((e) => e.value == this.model.idEvent)[0];
    if (event != undefined) return event.languages;
    return [];
  }

  get helpLink() {
    return this.translations.pageHelp.infoCardPage;
  }

  async created() {
    this.internal_events = await this.eventService.list();
    this.cardLayouts = await this.infoCardPageService.cardLayouts();

    this.model.event = this.events.filter(
      (e) => e.value == this.model.idEvent
    )[0];
    this.afterCreate();
  }
}
